@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'colors.css';

@font-face {
  font-family: Lausanne;
  src: url('../ui/src/fonts/TWK-Lausanne-300.woff2') format('woff2');
  font-weight: 100 300;
}

@font-face {
  font-family: Lausanne;
  src: url('../ui/src/fonts/TWK-Lausanne-400.woff2') format('woff2');
  font-weight: 400 900;
}

@layer base {
  :root {
    --header-height: 80px;
    --workflow-template-header-height: 48px;
    --document-asset-review-header: 52px;
    --document-asset-width: 680px;

    /*
      Add any color hsl values here
      Important: if any values in the design system change make sure to edit both the color name and value
    */
    --white: hsl(0 0% 100%);
    --black: hsl(0 0% 0%);

    --coral-100: 10 100% 92%;
    --coral-300: 9 100% 84%;

    --emerald-300: hsl(156 72% 67%);
    --emerald-500: hsl(160 84% 39%);
    --emerald-700: hsl(163 94% 24%);

    --green-700: 142 72% 29%;

    /* Use the values below, this makes it easier for us to see what colour is being used where  */
    --background: var(--white);
    --background-2: var(--slate-2);
    --background-3: var(--slate-3);
    --foreground: var(--slate-12);
    --foreground-2: var(--slate-9);

    /* Note: the below brand colours are custom and don't exist in tailwind */
    --brand: 218 62% 49%;
    --brand-green: 158deg 55% 58%;
    --brand-lilac: 257deg 85% 74%;
    --brand-indigo: 240deg 100% 73%;
    --brand-foreground: var(--white);

    --button-primary: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%),
      linear-gradient(0deg, var(--slate-12), var(--slate-12));

    --search: var(--slate-9);
    --search-asset: var(--blue-9);
    --search-project: var(--orange-9);
    --search-space: var(--pink-9);
    --search-organization: var(--green-9);

    --card: var(--white);
    --card-foreground: var(--slate-12);

    --popover: var(--white);
    --popover-foreground: var(--slate-12);

    --primary: var(--slate-12);
    --primary-foreground: var(--slate-1);
    --primary-muted: var(--slate-11);

    --secondary: var(--slate-3);
    --secondary-foreground: var(--slate-12);
    --secondary-muted: var(--slate-2);

    --muted: var(--slate-2);
    --muted-foreground: var(--slate-10);

    --accent: var(--slate-3);
    --accent-foreground: var(--slate-12);
    --accent-muted: var(--slate-5);

    --destructive: var(--red-10);
    --destructive-muted: var(--red-8);
    --destructive-foreground: var(--slate-1);

    --success: var(--jade-9);
    --success-muted: var(--jade-8);
    --success-foreground: var(--slate-1);

    --insertion: var(--jade-10);

    --warning: var(--yellow-9);
    --warning-muted: var(--yellow-10);
    --warning-foreground: var(--yellow-12);
    --warning-pale: var(--yellow-4);

    --interactive: var(--indigo-10);
    --interactive-muted: var(--indigo-6);
    --interactive-foreground: var(--slate-1);

    --info: var(--blue-3);
    --info-muted: var(--blue-4);
    --info-foreground: var(--blue-11);

    --magic: var(--violet-9);
    --magic-muted: var(--violet-11);
    --magic-foreground: var(--slate-1);

    --border: var(--slate-6);
    --border-hover: var(--slate-7);
    --border-active: var(--slate-8);
    --border-table: var(--slate-5);

    --badge: #2e2e27;
    --border-badge: rgba(46, 46, 39, 0.2745);
    --background-badge: rgba(244, 243, 239, 0.75);

    --healthy: #26763b;
    --border-healthy: rgba(36, 134, 62, 0.3);
    --issues: #b74018;
    --border-issues: rgba(207, 73, 30, 0.3);

    --in-progress: #4667a4;
    --border-in-progress: rgba(70, 103, 164, 0.3);

    --overdue: #b74018;
    --border-overdue: rgba(207, 73, 30, 0.3);

    --completed: #26763b;
    --border-completed: rgba(36, 134, 62, 0.3);

    --table-header: var(--slate-2);

    --input: var(--border);
    --input-hover: var(--border-hover);
    --input-active: var(--blue-8);
    --input-ring: var(--blue-6);

    --ring: var(--indigo-7);
    --ring-dark: var(--indigo-8);

    --radius: 0.5rem;

    --shadow-low: 0px 1px 2px 0px hsl(220 43% 11% / 5%);
    --shadow-base: 0px 1px 10px 4px hsl(220 43% 11% / 7%);
    --shadow-high: 0px 2px 12px 8px hsl(220 43% 11% / 12%);
    --shadow-sheet: 0px 2px 24px 0px rgba(9, 9, 11, 0.08), 0px 16px 32px 0px rgba(9, 9, 11, 0.08),
      0px 0px 0px 1px rgba(9, 9, 11, 0.08), 0px 0px 0px 1.5px rgba(228, 228, 231, 0.6) inset,
      0px 0px 0px 1px rgba(255, 255, 255, 1) inset;
    --shadow-input: 0px 1px 2px 0px hsla(240, 10%, 4%, 0.12);
    --shadow-button: var(--shadow-input), 0 1px 0 hsl(0 0 100 / 20%) inset;

    --input-xs: 1.25rem;
    --input-sm: 1.75rem;
    --input-md: 2.25rem;
    --input-lg: 3rem;
    --input-xl: 3.5rem;

    --form-builder: 32rem;
  }

  .dark {
    --background: var(--white);
    --background2: var(--slate-11);
    --background3: var(--slate-10);
    --foreground: var(--slate-1);

    --card: var(--slate-12);
    --card-foreground: var(--slate-1);

    --popover: var(--slate-12);
    --popover-foreground: var(--slate-1);

    --primary: var(--slate-1);
    --primary-foreground: var(--slate-11);

    --secondary: var(--slate-10);
    --secondary-foreground: var(--slate-1);

    --muted: var(--slate-11);
    --muted-foreground: var(--slate-7);

    --accent: var(--slate-10);
    --accent-foreground: var(--slate-1);

    --destructive: var(--red-8);
    --destructive-muted: var(--red-10);
    --destructive-foreground: var(--slate-1);

    --success: var(--emerald-500);
    --success-muted: var(--emerald-700);
    --success-foreground: var(--slate-1);

    --warning: var(--yellow-8);
    --warning-muted: var(--yellow-5);
    --warning-foreground: var(--slate-1);
    --warning-pale: var(--pale-yellow);

    --interactive: var(--indigo-8);
    --interactive-foreground: var(--indigo-10);
    --interactive-muted: var(--indigo-12);

    --highlight: var(--yellow-5);
    --highlight-muted: var(--yellow-2);

    --magic: var(--violet-5);
    --magic-muted: var(--violet-9);
    --magic-foreground: var(--slate-11);

    --border: var(--slate-6);
    --border-hover: var(--slate-7);
    --border-active: var(--slate-8);

    --input: var(--border);
    --ring: var(--indigo-9);

    --shadow-low: 0px 1px 5px 2px hsla(220, 43%, 11%, 0.05);
    --shadow-base: 0px 1px 10px 0px hsla(220, 43%, 11%, 0.07);
    --shadow-high: 0px 2px 12px 0px hsla(220, 43%, 11%, 0.12);
  }
}

@layer base {
  * {
    @apply border-border font-lausanne;
  }

  body {
    @apply bg-background text-foreground;
  }
}

div.cl-footer + [class^='cl-internal'] {
  display: none;
}

html,
body,
body > div:first-child,
div#__next {
  height: 100%;
}

/* needed to display placeholder text in tiptap */
.tiptap p.is-editor-empty:first-child::before {
  color: hsl(var(--muted-foreground));
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

/* required to style the emoji picker */
#emoji-picker aside.EmojiPickerReact.epr-main {
  @apply rounded-none border-none;
}

#emoji-picker .EmojiPickerReact .epr-header .epr-header-overlay {
  @apply px-2.5 py-[5px];
}

#emoji-picker .EmojiPickerReact .epr-category-nav {
  @apply px-2.5 py-[5px] pb-2.5;
}

#emoji-picker .EmojiPickerReact button.epr-emoji {
  @apply h-8 max-h-[32px] w-8 max-w-[32px];
}

#emoji-picker .EmojiPickerReact button.epr-emoji .epr-emoji-img {
  @apply max-h-[32px] min-h-[32px] min-w-[32px] max-w-[32px] p-1;
}

#emoji-picker .EmojiPickerReact .epr-search-container input.epr-search {
  @apply border-input bg-background text-foreground rounded-lg border border-solid text-sm;
}

#emoji-picker .EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
  @apply text-muted-foreground font-lausanne h-auto pb-3 text-xs font-semibold uppercase;
}

#emoji-picker .EmojiPickerReact ul.epr-emoji-list {
  @apply flex flex-col gap-3;
}

#emoji-picker .EmojiPickerReact .epr-category-nav > button.epr-cat-btn {
  @apply hover:bg-muted-foreground rounded-full bg-auto bg-center bg-no-repeat;
}

#emoji-picker .EmojiPickerReact .epr-category-nav > button.epr-cat-btn.epr-active {
  @apply bg-muted-foreground;
}
#emoji-picker .EmojiPickerReact .epr-category-nav > button.epr-cat-btn::before {
  @apply hidden;
}

#emoji-picker .EmojiPickerReact button.epr-cat-btn.epr-icn-suggested {
  background-image: url('/images/emoji-categories/suggested.svg');
}

#emoji-picker .EmojiPickerReact button.epr-cat-btn.epr-icn-smileys_people {
  background-image: url('/images/emoji-categories/smileys.svg');
}

#emoji-picker .EmojiPickerReact button.epr-cat-btn.epr-icn-animals_nature {
  background-image: url('/images/emoji-categories/animals.svg');
}

#emoji-picker .EmojiPickerReact button.epr-cat-btn.epr-icn-food_drink {
  background-image: url('/images/emoji-categories/food.svg');
}

#emoji-picker .EmojiPickerReact button.epr-cat-btn.epr-icn-travel_places {
  background-image: url('/images/emoji-categories/places.svg');
}

#emoji-picker .EmojiPickerReact button.epr-cat-btn.epr-icn-activities {
  background-image: url('/images/emoji-categories/activity.svg');
}

#emoji-picker .EmojiPickerReact button.epr-cat-btn.epr-icn-objects {
  background-image: url('/images/emoji-categories/objects.svg');
}

#emoji-picker .EmojiPickerReact button.epr-cat-btn.epr-icn-symbols {
  background-image: url('/images/emoji-categories/symbols.svg');
}

#emoji-picker .EmojiPickerReact button.epr-cat-btn.epr-icn-flags {
  background-image: url('/images/emoji-categories/flags.svg');
}

.document-asset-panel {
  max-height: calc(100vh - (var(--document-asset-review-header) * 2) - 1rem);
}

.is-editor-empty {
  color: var(--muted-foreground);
}
